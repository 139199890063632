import React, { FunctionComponent, ReactElement, useState } from 'react';
import { Props, State } from './MainLayout.interfaces';
import { Navbar, MobileNav, SponsorsButton } from '../../components';
import { Interface, defaultInterfaceContext } from '../../contexts';

export const MainLayout: FunctionComponent<Props> = ({ children }: Props): ReactElement<Props> => {
    const [state, setState] = useState<State>({
        interface: {
            ...defaultInterfaceContext,
            /**
             * Set the isNavOpen property in the Interface context
             * 
             * @param isNavOpen Boolean represents the next value of isNavOpen
             * @return void
             */
            toggleNav: (isNavOpen?: boolean): void => setState((state: State): State => {
                // Set to opposite of current value
                let navStatus = !state.interface.isNavOpen;

                // If value is passed, set navStatus to that value
                if (isNavOpen) {
                    navStatus = isNavOpen;
                }

                // Grab body element
                const bodyEl: Element | null = document.querySelector('body');

                // Check if we have element
                if (bodyEl)
                {
                    // Depending on navStatus, apply overflow css fix to prevent scrolling
                    if (navStatus)
                    {
                        bodyEl.classList.remove('applyOverflowXHidden');
                        bodyEl.classList.add('applyOverflowYHidden');
                    } else
                    {
                        bodyEl.classList.remove('applyOverflowYHidden');
                        bodyEl.classList.add('applyOverflowXHidden');
                    }
                }

                // Update state
                return {
                    interface: {
                        ...state.interface,
                        isNavOpen: navStatus,
                    },
                };
            }),
        },
    });

    return (
        <Interface.Provider value={state.interface}>
            <Navbar/>
            <MobileNav/>
            <main>
                {children}
                <SponsorsButton/>
            </main>
        </Interface.Provider>
    );
}
