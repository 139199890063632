import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    SEO,
    AttorneySummary1,
    AttorneySummary2,
    ScheduleDemo,
    GeneralJumbotron,
    SummaryTemplate,
    CallToAction,
} from '../components';
import { MainLayout } from '../layouts';
import { AttorneyImageQuery } from '../shared/interfaces';
import * as sharedStyles from '../styles/shared.module.scss';

const AttorneyPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<AttorneyImageQuery>(graphql`
        query {
            attorneyBackgroundImage: file(name: {eq: "attorneyBackground"}, extension: {eq: "png"}) {
                publicURL
            }
            californiaStateImage: file(name: {eq: "californiaState"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Attorney"/>
            <GeneralJumbotron
                title="Attorney"
                backgroundImageURL={queryResult?.attorneyBackgroundImage.publicURL}
                backgroundText="Attorney"
            />
            <AttorneySummary1/>
            <SummaryTemplate image={queryResult?.californiaStateImage.publicURL}>
                <h2>{`Statewide Physician Network`}</h2>
                <p>
                    {`
                        Here at PCH IPA, we know the importance of appropriate healthcare. Once enrolled in our system, your clients will have complete access to our extensive, statewide physician network. With the click of a button, they can connect with a provider of their choice and will have complete control over their doctor appointments and treatment plan; All the while, you and your staff will get real-time alerts and progress reports of their medical care and medical spend.
                    `}
                </p>
                <p>
                    {`
                        Our organization is the largest provider directory specializing in personal injury in California. With over 30,000 Providers, you can rest easy knowing that your clients are receiving the top-notch treatment they deserve!
                    `}
                </p>
            </SummaryTemplate>
            <section className={`${sharedStyles.generic} ${sharedStyles.attorneyFinancialSectionCorrection}`}>
                <div className={sharedStyles.genericContent}>
                    <h2
                        data-aos="fade-up"
                        data-aos-duration="900"
                    >{`Financial`}</h2>
                    <p data-aos="fade-up" data-aos-duration="900">
                        {`
                            Would you like to settle your client’s entire care with a single entity?
                        `}
                    </p>
                    <p data-aos="fade-up" data-aos-duration="900">
                        {`
                            With a PCH IPA membership, that is exactly what you will be getting: one bill.
                        `}
                    </p>
                </div>
            </section>
            <CallToAction/>
            <ScheduleDemo/>
        </MainLayout>
    );
};

export default AttorneyPage;
